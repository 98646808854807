import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Solitaire from '../../Assets/images/engagement/solitaire_ring.webp';
import Pave from '../../Assets/images/engagement/pave_ring.webp';
import Halo from '../../Assets/images/engagement/halo_ring.webp';
import Vintage from '../../Assets/images/engagement/vintage_ring.webp';
import ThreeStone from '../../Assets/images/engagement/three_stone_ring.webp';
import ChannelSet from '../../Assets/images/engagement/single_row_ring.webp';
import Multirow from '../../Assets/images/engagement/multirow_ring.webp';
import Bypass from '../../Assets/images/engagement/bypass_ring.webp';
import SideStone from '../../Assets/images/engagement/side_stone_ring.webp';
import other from '../../Assets/images/engagement/trellis_ring.webp';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import round from '../../Assets/images/front/round-diamond.png';

import oval from '../../Assets/images/front/princess-diamond.png';
import Cushion from '../../Assets/images/front/cushion-diamond.png';
import Pear from '../../Assets/images/front/emerald-diamond.png';
import Princess from '../../Assets/images/front/oval-diamond.png';
import Emerald from '../../Assets/images/front/radiant-diamond.png';
import Marquise from '../../Assets/images/front/asscher-diamond.png';
import Assche from '../../Assets/images/front/marquise-diamond.png';
import radiant from '../../Assets/images/front/heart-diamond.png';
import heartdiad from '../../Assets/images/front/pear-diamond.png';
import pr from '../../Assets/images/front/pear-diamond.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const RingStyle = () => {
  var history = useHistory();
  var diashapes = {
    dots: false,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: false,
    nextArrow: <HiOutlineChevronRight />,
    prevArrow: <HiOutlineChevronLeft />,
    slidesToShow: 10,
    slidesToScroll: 0,
    // fade: true,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          // dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          // nextArrow: <HiOutlineChevronDoubleRight />,
          // prevArrow: <HiOutlineChevronDoubleLeft />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          // nextArrow: <HiOutlineChevronDoubleRight />,
          // prevArrow: <HiOutlineChevronDoubleLeft />,
        },
      },
    ],
  };
  // var settings = {
  //     dots: false,
  //     infinite: true,
  //     autoplay: true,
  //     autoplaySpeed: 2000,
  //     slidesToShow: 4,
  //     slidesToScroll: 1,
  //     initialSlide: 0,
  //     responsive: [
  //         {
  //             breakpoint: 1024,
  //             settings: {
  //                 slidesToShow: 3,
  //                 slidesToScroll: 1,
  //                 infinite: true,
  //             }
  //         },
  //         {
  //             breakpoint: 600,
  //             settings: {
  //                 slidesToShow: 2,
  //                 slidesToScroll: 1,
  //                 initialSlide: 2
  //             }
  //         },
  //         {
  //             breakpoint: 480,
  //             settings: {
  //                 slidesToShow: 1,
  //                 slidesToScroll: 1
  //             }
  //         }
  //     ]
  // };

  // var settingsV2 = {
  //     dots: false,
  //     arrows: true,
  //     centerMode: true,
  //     // infinite: true,
  //     autoplay: false,
  //     autoplaySpeed: 2000,
  //     slidesToShow: 5,
  //     slidesToScroll: 1,
  //     initialSlide: 0,
  //     responsive: [
  //         {
  //             breakpoint: 1024,
  //             settings: {
  //                 slidesToShow: 3,
  //                 slidesToScroll: 1,
  //                 infinite: true,
  //             }
  //         },
  //         {
  //             breakpoint: 768,
  //             settings: {
  //                 slidesToShow: 3,
  //                 slidesToScroll: 1,
  //                 initialSlide: 2
  //             }
  //         },
  //         {
  //             breakpoint: 480,
  //             settings: {
  //                 slidesToShow: 1,
  //                 slidesToScroll: 1
  //             }
  //         }
  //     ]
  // };

  // const shopbystyle = (value) => {
  //     var data1 = {
  //         style: value,
  //     }
  //     sessionStorage.setItem("bw_s_filter", JSON.stringify(data1));
  //     history.push('/ringsettings/diamonds');
  // }
  return (
    <>
      {/* <Container className='rcs_custom_home_container mt-3' >
                <div className='rcs_ringstyle rcs_ringstyle_v2' >
                    <Row>
                        <Col lg={12} xs={12}>
                            <div className='rcs_ringstyle_title'>
                                <h2> SHOP BY STYLE </h2>
                            </div>
                        </Col>
                    </Row>

                    <Slider {...settingsV2}>
                        <div className='rcs_ringstyle_content_box '>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage src={Solitaire} alt='Solitaire' onClick={() => shopbystyle('Solitaire')}/>
                            </div>
                            <h2 onClick={() => shopbystyle('Solitaire')}>Solitaire</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Pave')} src={Pave} alt='Pave'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Pave')}>Pave</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Halo')} src={Halo} alt='Halo'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Halo')}>Halo</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Three stone')} src={ThreeStone} alt='Three Stone'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Three stone')}>Three Stone</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Channel Set')} src={ChannelSet} alt='Channel Set'/>

                            </div>
                            <h2 onClick={() => shopbystyle('Channel Set')}>Channel Set</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Multirow')} src={Multirow} alt='Multirow'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Multirow')}>Multirow</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Bypass')} src={Bypass} alt='Bypass'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Bypass')}>Bypass</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Side stone')} src={SideStone} alt='Side-stone'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Side stone')}>Side Stone</h2>
                        </div>

                    </Slider>

                </div>


            </Container> */}
      <section className="shop_diamond_shapes pt-0">
        <Container className="custum_contain">
          <div className="shop diamondss">
            <h2 class="title_one">Shop Lover*s Diamonds By Shape</h2>
          </div>
          <div className="diamd">
            <Slider className="home_baner_sliderone" {...diashapes}>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Round">
                    <img src={round} alt="round" />
                    <h5>Round</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Princess">
                    <img src={oval} alt="oval" />
                    <h5>Princess</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Cushion">
                    <img src={Cushion} alt="round" />
                    <h5>Cushion</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Emerald">
                    <img src={Pear} alt="Pear" />
                    <h5>Emerald</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Oval">
                    <img src={Princess} alt="Princess" />
                    <h5>Oval</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Radiant">
                    <img src={Emerald} alt="Emerald" />
                    <h5>Radiant</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Asscher">
                    <img src={Marquise} alt="Marquise" />
                    <h5>Asscher</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Marquise">
                    <img src={Assche} alt="Assche" />
                    <h5>Marquise</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Heart">
                    <img src={radiant} alt="radiant" />
                    <h5>Heart</h5>
                  </Link>
                </div>
              </div>
              <div className="slide_shp">
                <div className="dia_shape">
                  <Link to="/diamonds/lab/shape=Pear">
                    <img src={pr} alt="per" />
                    <h5>Pear</h5>
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
        </Container>
      </section>
    </>
  );
};

export default RingStyle;
