import React, { useState } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row, Form, Modal } from 'react-bootstrap';
import signupTo from "../../Assets/images/home/signup-to.webp";
import { base_url, client_name, currency, currencycode, helmet_url, postHeader } from '../../Helpers/request';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { LazyLoadImage } from "react-lazy-load-image-component";
import south from '../../Assets/images/home/south.png'
function SignupTo() {
    const history = useHistory();
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader,setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            currency_code: currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }
    return (
        <>
            {/* <section className="rcs_signup_to_section mt-5">
                <Container fluid>
                    <Row>
                        <Col lg={5} xs={12}  data-aos="fade-up" data-aos-duration="1200"  data-aos-easing="ease-in-sine">
                            <div className="rcs_signup_to_left_main">
                                <div className="rcs_signup_to_title">
                                    <h2> sign up to be a <br /> {client_name.c_name} insider </h2>
                                    <p >Become a preferred subscriber and get a special offer on your purchase.</p>
                                </div>
                                <div className="rcs_signup_to_input_field">
                                    <Form className="gs_news_form w-100" onSubmit={newsletter}>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder="Email Address..."
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                type="email"
                                                value={newsletteremail}
                                                required
                                                onChange={(e) => setNewsletteremail(e.target.value)}
                                            />
                                            <Button variant="outline-secondary" type="submit" id="button-addon2">
                                                Join
                                            </Button>
                                        </InputGroup>
                                    </Form>
                                    <p>By signing up you confirm that you have read the <a href="/privacy-policy">Privacy Policy</a> and agree that your email
                                        will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} xs={12} className="ml-auto pr-0 p-0">
                            <div className="  rcs_signup_to_right_img">
                                <LazyLoadImage src={signupTo} alt="signup" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal show={loader}>
            <Loader/>
        </Modal> */}
        <section className='get_In_touch'>
       <Container className='custum_contain'>
       <Row className='g-0' no-gutter>
        <Col md={12} sm={12} lg={6} no-gutter className='p-md-0 p-3'>
         <div className='back_gry'>
            <div className='get_in'>
            <h2 className="title_one">Get In Touch</h2>
            <ul className='info_icons'>
                <li><a target='_blank' href='https://goo.gl/maps/Jp3jmyQpZP2gPmTN7'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> <span>606 South Hill Street, Suite #806, <br/>
Los Angeles, CA 90014</span></a></li>
<li> <a target='_blank' href='tel:+1 (213)-550-6870'> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> <span>+1 (213)-550-6870</span></a></li>
<li><a target='_blank' href='mailto:info@loversdiamond.com'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.9024 8.85107L13.4591 12.4641C12.6196 13.1301 11.4384 13.1301 10.5989 12.4641L6.11816 8.85107" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9089 21C19.9502 21.0084 22 18.5095 22 15.4384V8.57001C22 5.49883 19.9502 3 16.9089 3H7.09114C4.04979 3 2 5.49883 2 8.57001V15.4384C2 18.5095 4.04979 21.0084 7.09114 21H16.9089Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg><span>info@loversdiamond.com</span></a></li>
<li><a target='_blank' href='mailto:pujamehta@loversdiamond.com'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M17.9024 8.85107L13.4591 12.4641C12.6196 13.1301 11.4384 13.1301 10.5989 12.4641L6.11816 8.85107" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9089 21C19.9502 21.0084 22 18.5095 22 15.4384V8.57001C22 5.49883 19.9502 3 16.9089 3H7.09114C4.04979 3 2 5.49883 2 8.57001V15.4384C2 18.5095 4.04979 21.0084 7.09114 21H16.9089Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg><span>pujamehta@loversdiamond.com</span></a></li>
            </ul>
            <div className='two_buttons'>
                <Button variant="primary" onClick={() => {history.push('contact')}} className='settings setbg br-0'>Visit Our Showroom</Button>
                <Button variant="primary" onClick={() => {history.push('make-an-appointment')}} className='settings setborde'>Book a Virtual Appointment</Button> 
                </div>
            </div>
         </div>
        </Col>
        <Col  md={12} sm={12} lg={6} no-gutter className='nop'>
          <div className='get_img'>
            <div className='soutyh_img'>
                <img src={south} alt='get in touch' className='img-fluid'></img>
            </div>
          </div>
        </Col>

       </Row>
       </Container>
        </section>
        </>
    );
}


export default SignupTo;
