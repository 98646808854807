// import { Button } from '@mui/material';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import ringSetting from '../../Assets/images/home/ring-setting.webp';
import settingDiamonds from '../../Assets/images/home/setting-diamonds.webp';
import finalRing from '../../Assets/images/home/final-ring.webp';
import packrings from '../../Assets/images/home/dream.png';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BsArrowRight } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
const Ringbuilderhome = () => {
  const history = useHistory();
  return (
    <>
      {/* <div className='rcs_ringbuilder_home'> */}
      {/* <Container className='rcs_custom_home_container mt-3'> */}
      {/* <Row className='w-100 m-auto'>
                        <Col>
                            <div className='rcs_ringbuilder_top_head mb-5 mb-md-2' data-aos="fade-up">
                                <h2>Create your own</h2>
                                <p> Diamond ring </p>
                            </div>
                        </Col>
                    </Row> */}
      {/* <Row className='w-100 m-auto justify-content-center'> */}
      {/* <Col xs={12} sm={12} md={1}></Col> */}
      {/* <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className='rcs_diamonds_ring'>
                                <Row className='w-100 align-items-center justify-content-center mt-5 mt-md-4 mr-0 ml-0'>                                    
                                    <Col xs={12} sm={12} md={5} className="p-0">
                                        <div className='rcs_diamonds_ring_content text-center' data-aos="fade-up" data-aos-offset="300"  data-aos-duration="700" data-aos-easing="ease-in-sine">
                                            <h2>Step 1</h2>
                                            <p>Choose a setting</p>
                                            <LazyLoadImage src={ringSetting} onClick={() => history.push('/engagement-rings')} alt="ring setting"/>
                                            <Button variant="contained" onClick={() => history.push('/engagement-rings')} className='rcs_ringbuilder_button br-0'>start with a setting</Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={2}>
                                        <div className='rcs_diamonds_ring_add_content text-center' data-aos="fade-up" data-aos-easing="ease-in-sine"  data-aos-duration="900">
                                            <h3>+</h3>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} className="p-0">
                                        <div className='rcs_diamonds_ring_content text-center' data-aos="fade-up" data-aos-offset="300" data-aos-duration="1100" data-aos-easing="ease-in-sine">
                                            <h2>Step 2</h2>
                                            <p>choose a diamond</p>
                                            <LazyLoadImage src={settingDiamonds} onClick={() => history.push('/diamonds')} alt='diamond'/>
                                            <Button variant="contained" onClick={() => history.push('/diamonds')} className='rcs_ringbuilder_button'>start with a diamond</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12}  lg={2} xl={1}>
                            <div className='rcs_diamonds_ring_equal d-flex align-items-center justify-content-center position-relative h-100' data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine"  data-aos-duration="1400">
                                <h3>=</h3>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='rcs_diamonds_ring_final'  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine"  data-aos-duration="1400">
                                <LazyLoadImage src={finalRing} alt='diamond'/>
                            </div>
                        </Col> */}
      {/* </Row> */}
      {/* </Container> */}
      {/* </div> */}

      <section className="Create_Your_Dream pt-5">
        <Container className="custum_contain">
          <div className="dream">
            {/* <Row> */}
            {/* <Col md={6}> */}
            <div className="two_rings">
              <div className="dim">
                <img
                  src={packrings}
                  className="img-fluid"
                  alt="Create Your Dream"
                />
              </div>
            </div>
            {/* </Col> */}
            {/* <Col md={6}> */}
            <div className="design align-self-center">
              <div className="reflect">
                <h4>Create Your Dream</h4>
                <h2>Lover*s Diamond Ring</h2>
                <p>
                  Design a Lover*s Diamond Ring That Reflects Your Style and
                  Personality.
                </p>
                <div className="two_buttons">
                  <Button
                    variant="primary"
                    onClick={() => history.push('/engagement-rings')}
                    className="settings setbg br-0"
                  >
                    start with a setting
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="16"
                      viewBox="0 0 19 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.3431 0.92888L18.7071 7.29284C19.0976 7.68336 19.0976 8.31653 18.7071 8.70705L12.3431 15.071C11.9526 15.4615 11.3195 15.4615 10.9289 15.071C10.5384 14.6805 10.5384 14.0473 10.9289 13.6568L15.5858 8.99995H0V6.99995H15.5858L10.9289 2.34309C10.5384 1.95257 10.5384 1.3194 10.9289 0.92888C11.3195 0.538355 11.9526 0.538355 12.3431 0.92888Z"
                        fill="white"
                      />
                    </svg>
                  </Button>
                  <span>OR</span>
                  <Button
                    variant="primary"
                    onClick={() => history.push('/diamonds')}
                    className="settings setborde"
                  >
                    start with a diamond
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="16"
                      viewBox="0 0 19 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.3431 0.92888L18.7071 7.29284C19.0976 7.68336 19.0976 8.31653 18.7071 8.70705L12.3431 15.071C11.9526 15.4615 11.3195 15.4615 10.9289 15.071C10.5384 14.6805 10.5384 14.0473 10.9289 13.6568L15.5858 8.99995H0V6.99995H15.5858L10.9289 2.34309C10.5384 1.95257 10.5384 1.3194 10.9289 0.92888C11.3195 0.538355 11.9526 0.538355 12.3431 0.92888Z"
                        fill="#0F75BC"
                      />
                    </svg>
                  </Button>
                </div>
              </div>
            </div>
            {/* </Col> */}
            {/* </Row> */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Ringbuilderhome;
