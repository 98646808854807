import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import ConsultImg from '../../Assets/images/home/consult-2.webp';
import calendarIcn from '../../Assets/images/home/calender-icn.webp';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import returnpolicy from '../../Assets/images/home/return.png';
import freeship from '../../Assets/images/home/freeship.png';
import diamondexperts from '../../Assets/images/home/diamondexperts.png';
import moneyback from '../../Assets/images/home/moneyback.png';

const Virtualappointment = () => {
  const history = useHistory();
  return (
    <>
      {/* <section className="rcs_color_virtual_section1">
                <Container className="rcs_custom_home_container pr-0">
                    <Row className="rcs_virtual_appointment_row">                        
                        <Col lg="6" col="12" className="rcs_order_cs">
                            <div className="rcs_virtual_appointment_text" data-aos="fade-up">
                               <h2> Consult with a Jewelry Specialist </h2>
                               <p>
                               See the collections in person at either of our <br/>showrooms located in NYC
                               </p>
                                <Button className="rcs_fill_button rcs_btn_rd-0 rcs_new_bg" onClick={() => {history.push('make-an-appointment');window.scrollTo(0,0)}}> 
                                  <LazyLoadImage src={calendarIcn} /> Schedule an Appointment
                                </Button>

                            </div>
                        </Col>
                        <Col lg="6" col="12" className="p-0">
                            <div className="rcs_virtual_appointment_img">
                              <LazyLoadImage src={ConsultImg} alt="Jewelry-Specialist" title="Jewelry-Specialist"/>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section> */}
      <section className="our_promice">
        <Container className="custum_contain">
          <div className="ourhed">
            <h2 className="title_one">Our Promise to you...</h2>
          </div>
          <div className="our">
            <Row>
              <Col md={3}>
                <div className="box">
                  <div className="return">
                    <img src={returnpolicy} alt="return" />
                  </div>
                  <div className="Return_texts">
                    <h4>Returns</h4>
                    <p>
                      Enjoy Easy Returns Within 30 Days
                      <br />
                      of Purchase.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="box">
                  <div className="return">
                    <img src={freeship} alt="return" />
                  </div>
                  <div className="Return_texts">
                    <h4>Free Shipping</h4>
                    <p>
                      No More Shipping Fees! Free Shipping
                      <br />
                      on All Orders.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="box">
                  <div className="return">
                    <img src={diamondexperts} alt="return" />
                  </div>
                  <div className="Return_texts">
                    <h4>Lover*s Diamond Experts</h4>
                    <p>
                      Get Personalized Assistance from Our
                      <br />
                      24/7 Diamond Experts.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="box">
                  <div className="return">
                    <img src={moneyback} alt="return" />
                  </div>
                  <div className="Return_texts">
                    <h4>Money Back Guarantee</h4>
                    <p>
                      Try Our Products Risk-Free with Our
                      <br />
                      Money Back Guarantee.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Virtualappointment;
