import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import round from "../../Assets/images/home/solitaire1.png";
import RoundShape from "../../Assets/images/style/solitaire.png";
import princess from "../../Assets/images/style/vintage.png";
import cushion from "../../Assets/images/style/channel.png";
import emerald from "../../Assets/images/style/eternity.png";
import oval from "../../Assets/images/style/curved.png";
import radiant from "../../Assets/images/style/aniversary.png";
import asscher from "../../Assets/images/style/halo.png";
import marquise from "../../Assets/images/style/stackable.png";
import heart from "../../Assets/images/style/sidestone.png";
import pear from "../../Assets/images/style/pave.png";

import roundImg from "../../Assets/images/style/solitaire.png";
import princessImg from "../../Assets/images/style/vintage.png";
import cushionImg from "../../Assets/images/style/channel.png";
import emeraldImg from "../../Assets/images/style/eternity.png";
import ovalImg from "../../Assets/images/style/curved.png";
import radiantImg from "../../Assets/images/style/aniversary.png";
import asscherImg from "../../Assets/images/style/halo.png";
import marquiseImg from "../../Assets/images/style/stackable.png";
import heartImg from "../../Assets/images/style/sidestone.png";
import pearImg from "../../Assets/images/style/pave.png";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom';




function ShopDiamondShape() {
var history = useHistory();
    var settings2 = {
        dots: false,
        infinite: true,
        centerMode: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
   const shopbyshape = (value) =>{
    var data1 = {
        shape: value,
    } 
    sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
    history.push('/diamonds');
    window.scrollTo(0, 0);
    }

    return (
        <>
            <section className="rcs_ring_style_section">
                <Container className="rcs_custom_home_container">
                    <Row>
                        <Col md={12}>
                            <div className="rcs_shop_by_diamond_title" data-aos="fade-up">
                                <h2 className='title_one'>Shop by Style</h2>
                            </div>
                        </Col>

                        <Col lg={12} className="p-0">
                            <div className="rcs_customer_review_slider" data-aos="fade-up">
                                <Slider className="rcs_customer_say_inner rcs_shop_diamond_slider" {...settings2}  >

                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/engagement-rings/style=Solitaire">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={RoundShape} alt="Round"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={roundImg} alt="Round" />
                                                </div>
                                            </Link>
                                          
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/engagement-rings/style=Solitaire">
                                              <h3 className="m-0 text-dark">Solitaire</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/engagement-rings/style=Solitaire">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={princess} alt="Princess"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={princessImg} alt="Princess"/>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="ringsettings/style=Vintage">
                                                <h3 className="m-0 text-dark">Vintage</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Channel%20Set">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={cushion} alt="Cushion"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={cushionImg} alt="Cushion"/>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Channel%20Set">
                                                <h3 className="m-0 text-dark">Channel-set</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Eternity">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={emerald} alt="Emerald"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={emeraldImg} alt="Emerald"/>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Eternity">
                                                <h3 className="m-0 text-dark">Eternity</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    
                                    <div className="rcs_shop_by_diamond_box_main">
                                            <div className="rcs_shop_by_diamond_box_img">
                                                <Link to="/jewelry/wedding-rings/style=Curved">
                                                    <div className="rcs_shop_by_diamond_box_img_size">
                                                        <Image className="rcs_shop_by_diamond_box_img_static" src={oval} alt="Oval"/>
                                                        <Image className="rcs_shop_by_diamond_box_img_hover" src={ovalImg} alt="Oval"/>
                                                    </div>
                                                </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Curved">
                                                <h3 className="m-0 text-dark">Curved</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Anniversary">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={radiant} alt="Radiant"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={radiantImg} alt="Radiant"/>
                                                </div>
                                            
                                            </Link>
                                            
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Anniversary">
                                                <h3 className="m-0 text-dark">Aniversary</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div clasName="rcs_shop_by_diamond_box_img">
                                            <Link to="/engagement-rings/style=Halo">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={asscher} alt="Asscher"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={asscherImg} alt="Asscher"/>
                                                </div>
                                            
                                            </Link>
                                           
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/engagement-rings/style=Halo">
                                                <h3 className="m-0 text-dark">Halo</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/jewelry/wedding-rings/style=Stackable">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={marquise} alt="Marquise"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={marquiseImg} alt="Marquise"/>
                                                </div>
                                            
                                            </Link>
                                         
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/jewelry/wedding-rings/style=Stackable">
                                                <h3 className="m-0 text-dark">Stackable</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link>
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={heart} alt="Heart"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={heartImg} alt="Heart"/>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="ringsettings/style=Solitaire">
                                                <h3 className="m-0 text-dark">Sidestone</h3>
                                            </Link>
                                            </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img">
                                            <Link to="/engagement-rings/style=Pave">
                                                <div className="rcs_shop_by_diamond_box_img_size">
                                                    <Image className="rcs_shop_by_diamond_box_img_static" src={pear} alt="Heart"/>
                                                    <Image className="rcs_shop_by_diamond_box_img_hover" src={pearImg} alt="Heart"/>
                                                </div>
                                            
                                            </Link>
                                        </div>
                                        <div className="rcs_shop_by_diamond_box_text mt-5">
                                            <Link to="/engagement-rings/style=Pave">
                                                <h3 className="m-0 text-dark">Pave</h3>
                                            </Link>
                                        </div>
                                    </div>
                                    
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}


export default ShopDiamondShape;
