import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import abtimg from '../../Assets/images/home/maskgroup.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function Gemstones() {
  const history = useHistory();
  return (
    <>
      {/* <section className="rcs_gemstone_section" >
                <Container className="rcs_custom_home_container ">
     
                        <Col md={8} lg={7} className="ml-auto">
                            <div className="rcs_gemstone_content" data-aos="fade-up">
                                <h2> The World's Most Vivid Gemstones </h2>
                                <Button className="rcs_fill_button rcs_btn_rd-0  btn btn-primary" onClick={() => history.push('gemstones')}> Shop gemstones </Button>
                            </div>
                        </Col>
                   
                </Container>
            </section> */}
      <section className="about_lover_diamod">
        <Container className="custum_contain">
          <div className="ablov">
            <Row>
              <Col md={12} lg={6} sm={12}>
                <div className="abt_img">
                  <div className="abt_lov_img text-right">
                    <img
                      src={abtimg}
                      alt="About Lover*s Diamond"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </Col>
              <Col md={12} lg={6} sm={12}>
                <div className="abtout">
                  <div className="about_lover_text text-lg-left text-center">
                    <h5>About Lover*s Diamond</h5>
                    <p>
                      Welcome to a new way to celebrate love and create special
                      memories, with the sparkle of real man-made lover*s
                      diamonds! Our team is passionate about helping
                      loverscapture and create memorable moments to last a
                      lifetime. We proudly produce theworld’s top collection of
                      Earth-friendly lover*s diamonds of all shapes and sizes,
                      and our team of lover*s diamonds experts will collaborate
                      with you to curate a masterpiece for any occasion.From
                      engagement rings to lovely signature jewelry for him and
                      for her, anniversaryrings, bracelets, earrings and
                      necklaces, or anything your heart desires. We will walk
                      you through every step of the process, providing excellent
                      service, quality creations and building a relationship
                      that will last a lifetime.
                    </p>
                    <Link
                      className="know btn btn-primary"
                      to="/our-story"
                      variant="primary"
                    >
                      Know More
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Gemstones;
