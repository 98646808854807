import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import collection1 from "../../Assets/images/home/maskgroup(6).png";
import collection2 from "../../Assets/images/home/maskgroup(4).png";
import collection3 from "../../Assets/images/home/maskgroup(7).png";
import collection4 from "../../Assets/images/home/maskgroup(5).png";
import icnLeft from "../../Assets/images/icon/Ex-right-icn.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const ExploreCollection = () => {
    var settings2 = {
        dots: false,
        // nextdots:ur,
        // prevdots: <HiOutlineChevronDoubleLeft />,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>

            <section className="rcs_collection_section1 rcs_collection_section1_desk">
                <Container className="rcs_custom_home_container">
                    <Row>
                        <Col lg="12" col="12">
                            <div className="rcs_collection1_title" >
                                <h2 className='title_one m-0'>Shop By Category </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="rcs_exp_collection_main">
                                <ul>
                                    <li>
                                        <div className="rcs_collection1_box_img rcs_collection_height">
                                            <LazyLoadImage src={collection2} alt="shop-ring" title="shop-ring" className='img-fluid' />
                                            {/* <div className="rcs_collection1_box_btn rcs_collection1_btn_hover">
                                                <Button className="rcs_fill_button rcs_btn_rd-0 rcs_btn_rcs_btn_shadow">  
                                                <Link to="/jewelry/fashion-rings"> Shop Now  </Link> </Button>
                                            </div> */}
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                            <Link to="/jewelry/fashion-rings">   <h2> Diamond Rings </h2>  </Link>
                                            <Button className="shop_expore">  
                                                <Link to="/jewelry/fashion-rings"> Shop Now  </Link> </Button>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="rcs_collection1_box_img rcs_collection_height">
                                            <LazyLoadImage src={collection4} alt="shop-necklace" title="shop-necklace"className='img-fluid' />
                                            {/* <div className="rcs_collection1_box_btn rcs_collection1_btn_hover">
                                                <Button className="rcs_fill_button rcs_btn_rd-0 rcs_btn_rcs_btn_shadow">  <Link to="/jewelry/necklaces"> shop NECKLACES </Link>   </Button>
                                            </div> */}
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                            <Link to="/jewelry/bracelets"><h2>Diamond Bracelets</h2></Link>
                                            <Button className="shop_expore">  
                                                <Link to="/jewelry/bracelets"> Shop Now  </Link> </Button>
                                        </div>
                                    </li>

                                    <li>

                                        <div className="rcs_collection1_box_img rcs_collection_height">
                                            <LazyLoadImage src={collection1} alt="shop-earring" title="shop-earring" className='img-fluid' />
                                            <div className="rcs_collection1_box_btn rcs_collection1_btn_hover">
                                            </div>
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                            <Link to="/jewelry/earrings"> <h2>Diamond Earrings</h2></Link>
                                            <Button className="shop_expore">  
                                                <Link to="/jewelry/earrings">Shop Now</Link>  </Button>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="rcs_collection1_box_img rcs_collection_height">
                                            <LazyLoadImage src={collection3} alt="shop-bracelet" title="shop-bracelet" className='img-fluid' />
                                            <div className="rcs_collection1_box_btn rcs_collection1_btn_hover">
                                                
                                            </div>
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                            <Link to="/jewelry/pendants"> <h2>Diamond Pendants</h2> 
                                            </Link>
                                            <Button className="shop_expore">  
                                                <Link to="/jewelry/pendants"> Shop Now </Link>  </Button>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="rcs_collection_section1 d-lg-none">
                <Container className="rcs_custom_home_container">
                    <Row>
                        <Col lg="12" col="12">
                            <div className="rcs_collection1_title rcs__mobile_collection_title">
                                <h2> EXPLORE OUR COLLECTION </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="rcs_customer_review_slider">
                                <Slider className="rcs_customer_say_inner" {...settings2}>


                                    <div className="rcs_collection1_box rcs_mobile_collection1_box">

                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={collection2} alt="shop-ring" title="shop-ring" />
                                        </div>
                                        <div className="rcs_collection1_box_btn ">
                                            <Link to="/jewelry/fashion-rings">
                                                <h2> Rings </h2>
                                                <LazyLoadImage src={icnLeft} />
                                            </Link>
                                        </div>


                                    </div>
                                    <div className="rcs_collection1_box rcs_mobile_collection1_box">

                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={collection4} alt="shop-necklace" title="shop-necklace" />
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                            <Link to="/jewelry/necklaces">
                                                <h2> Necklaces </h2>
                                                <LazyLoadImage src={icnLeft} />
                                            </Link>
                                        </div>

                                    </div>
                                    <div className="rcs_collection1_box rcs_mobile_collection1_box" >

                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={collection1} alt="shop-earring" title="shop-earring" />
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                            <Link to="/jewelry/earrings">
                                                <h2> Earrings </h2>
                                                <LazyLoadImage src={icnLeft} />
                                            </Link>
                                        </div>

                                    </div>
                                    <div className="rcs_collection1_box rcs_mobile_collection1_box">

                                        <div className="rcs_collection1_box_img">
                                            <LazyLoadImage src={collection3} alt="shop-bracelet" title="shop-bracelet" />
                                        </div>
                                        <div className="rcs_collection1_box_btn">
                                            <Link to="/jewelry/bracelets">
                                                <h2> Bracelets </h2>
                                                <LazyLoadImage src={icnLeft} />
                                            </Link>
                                        </div>

                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>



        </>
    );
}
export default ExploreCollection;
