import React from 'react';

import Banner1 from '../../Assets/images/Banner-1- 2.png';
import Banner2 from '../../Assets/images/Banner-2- 1.png';
import Banner3 from '../../Assets/images/Banner-3- 2.png';

import BannerMobile1 from '../../Assets/images/mobile-Banner-1-.jpg';
import BannerMobile2 from '../../Assets/images/mobile-Banner-2-.jpg';
import BannerMobile3 from '../../Assets/images/mobile-Banner-3-.jpg';

import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { isMobile } from 'react-device-detect';

const ParallaxBanner = () => {
  return (
    <div className="herosection parallax-herosection">
      <Parallax bgImage={isMobile ? BannerMobile1 : Banner1} strength={300}>
        <div style={{ height: 'calc(100vh - 120px)' }}>
          <div className="content">
            <h2>Sparkling wonders of science</h2>
            <p>
              Lover*s lab-made diamonds, with their radiant brilliance,
              represent a remarkable achievement of human ingenuity. Through
              precise scientific processes that involve absolutely no mining or
              destruction of the Earth, these Lover*s diamonds are meticulously
              grown in laboratories, and offer the same formations as Lover*s
              diamonds from deep within the Earth.
            </p>
            {/* <Link to="/diamonds" className='know btn btn-primary'>Shop Now</Link> */}
          </div>
        </div>
      </Parallax>
      <Parallax bgImage={isMobile ? BannerMobile2 : Banner2} strength={300}>
        <div style={{ height: 'calc(100vh - 120px)' }}>
          <div className="content">
            <h2>A Sustainable Sparkle for lovers of all kinds</h2>
            <p>
              Lover*s lab-made diamonds not only offer a stunning alternative to
              traditional mined diamonds but also help pave the way for a
              sustainable future. By reducing the demand for mining, these
              lab-grown gems contribute to the preservation of our fragile
              ecosystems and reduce the carbon footprint associated with diamond
              extraction. Moreover, their controlled production allows for
              greater transparency in the supply chain, ensuring that consumers
              can trace the origin of their cherished gemstones. As we celebrate
              the radiant brilliance of these lab-made Lover*s diamonds, we also
              celebrate a brighter, more sustainable tomorrow.
            </p>
            {/* <Link to="/ringsettings" className='know btn btn-primary'>Shop Now</Link> */}
          </div>
        </div>
      </Parallax>
      <Parallax bgImage={isMobile ? BannerMobile3 : Banner3} strength={300}>
        <div style={{ height: 'calc(100vh - 120px)' }}>
          <div className="content">
            <h2>
              Lover
              <span
                style={{
                  fontFamily: "'Josefin Sans', sans-serif",
                  fontWeight: 400,
                }}
              >
                *
              </span>
              <span style={{ textTransform: 'lowercase' }}>s</span> Diamonds as
              Eternal Emblems
            </h2>
            <p>
              Lover*s Diamonds are enduring symbols of love, embodying
              commitment, strength, and resilience. Their fiery brilliance
              mirrors the passion and intensity of love, and their journey from
              creation to adornment mirrors the journey of love itself. Lover*s
              Diamonds serve as cherished emblems of lover*s eternal embrace,
              reminding us of timeless love stories that inspire us generation
              after generation.
            </p>
            <Link to="/jewelry/wedding-rings" className="know btn btn-primary">
              Shop Now
            </Link>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default ParallaxBanner;
