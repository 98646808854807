import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../Assets/css/home.css';
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import Insta from './insta';
import ExploreCollection from './ExploreCollection';
import Virtualappointment from './VirtualAppointment';
import Customer from './Customer';
import ShopDiamondShape from './ShopDiamondShape';
import Gemstones from './GemstoneShop';
import RingStyle from './RingStyle';
import Ringbuilderhome from './Ringbuilderhome';
import SignupTo from './SignupTo';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux';
import { client_name, sku_diamond } from '../../Helpers/request';
import ParallaxBanner from './ParallaxBanner';
import { Modal, Image } from 'react-bootstrap';

import { Link } from 'react-router-dom/cjs/react-router-dom';
import Dia from '../../Assets/images/dia.png';
import HoverPop from '../../Assets/images/popup.jpg';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IoClose } from 'react-icons/io5';

const Home = () => {
  const metaDetails = useSelector((state) => state?.persistedReducer?.meta?.meta);
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setTimeout(() => {
      handleOpen();
    }, 3000);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  // useEffect(() => {
  //   // Add 'modal-open' class to body when modal is open
  //   if (open) {
  //     document.querySelector('html').classList.add('modal-open');
  //   } else {
  //     // Remove 'modal-open' class when modal is closed
  //     document.querySelector('html').classList.remove('modal-open');
  //   }

  //   // Cleanup effect
  //   return () => {
  //     document.querySelector('html').classList.remove('modal-open');
  //   };
  // }, [open]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails?.title}</title>
        <meta name="description" content={metaDetails?.description}></meta>
        <meta name="keywords" content={metaDetails?.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content={client_name.c_name} />
      </Helmet>

      {/* <BannerSlider /> */}
      <ParallaxBanner />
      <RingStyle />
      <Ringbuilderhome />
      <ShopDiamondShape />
      <ExploreCollection />
      <Gemstones />
      <Virtualappointment />
      {/* <Customer /> */}
      {/* <Insta /> */}
      <SignupTo />

      {/* <Modal show={open} centered aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="bubble-diamond-popup" onClose={() => handleClose(false)}>
        <div className="inner-popup">
          <img src={Dia} alt="" />
          <Typography variant="h6" component="h2">
            Buy One Carat Diamond
            <br /> for <span>$499</span> <br />
            and get a 14k Gold Setting for Free
          </Typography>
          <div className="footer d-flex flex-column">
            <Link variant="primary" to={`/special-diamonds-details/${sku_diamond}`}>
              Buy Now
            </Link>
            <Button variant="secondary" className="mt-2" onClick={() => handleClose(false)}>
              No Thanks
            </Button>
          </div>
        </div>
      </Modal> */}

      <Modal show={open} className="offer-popup" centered onHide={handleClose}>
        <Modal.Header>
          <button className="close" onClick={handleClose}>
            <IoClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Link variant="primary" to={`/special-diamonds-details/${sku_diamond}`}>
            <Image src={HoverPop} alt="" />
          </Link>
        </Modal.Body>
      </Modal>

      {/* <AboutSection/> */}
      {/* <Colordiamond /> */}
      {/* <Letestcreation/> */}
      {/* <ServiceSection /> */}
      {/* <SignupTo /> */}
      {/* <Newsletter /> */}
      {/* <Featured /> */}
      {/*Collection1 :- Wedding band,Diamonds Earrings,Fine Jewelry */}
      {/* <Collection1 /> */}
      {/* <StateJewelry /> */}
      {/*Collection2 :- Pendants,Bracelets,Gemstones */}
      {/* <Collection2 /> */}
      {/* <IndianJewelry /> */}
      {/* <Philanthropy /> */}
      {/* <Testimonial /> */}
      {/* <Insta /> */}
      {/* <Newsletter /> */}
    </>
  );
};

export default Home;
